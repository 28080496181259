<template>
  <div
    v-if="visible"
    class="bold fixed upgrade-bar bg-online q-py-xs text-white text-center pointer"
    @click="reload"
  >
    <QIcon name="ion-information-circled" />
    <span>
      {{
        $t("upgrade_bar.new_version_of_platform", {
          platformName: appConfig.platformName,
        })
      }}
    </span>
  </div>
</template>

<script>
import StorageService from "shared/services/StorageService";

const FIVE_MINUTES = 5 * 60 * 1000;
const EIGHTEEN_HOURS = 18 * 60 * 60 * 1000;

import { storeToRefs } from "pinia";

import { useGlobalStore } from "shared/stores/global";

export default {
  name: "UpgradeBar",
  setup() {
    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    return {
      appConfig,
    };
  },
  data() {
    return {
      interval: null,
      visible: false,
      showAgainAt: 0,
      loadedVersion: {
        hash: "__commit_hash__",
        version: "__version_number__",
      },
    };
  },
  async mounted() {
    if (import.meta.env.VITE_ENABLE_UPGRADE_BAR) {
      this.interval = setInterval(async () => {
        if (!this.visible) {
          await this.checkForNewVersion();
        }
      }, FIVE_MINUTES);

      this.showAgainAt =
        ((await StorageService.get("lastUpdate", sessionStorage)) || 0) +
        EIGHTEEN_HOURS;
    }
  },
  beforeUnmount() {
    if (this.interval) clearInterval(this.interval);
  },
  methods: {
    shouldShowBar(onlineVersion) {
      return (
        onlineVersion.version !== this.loadedVersion.version ||
        (this.loadedVersion.hash !== onlineVersion.hash &&
          this.showAgainAt >= Date.now())
      );
    },
    async checkForNewVersion() {
      try {
        const onlineVersion = (
          await this.$streemApiV1.get("version.json", {
            baseURL: window.location.origin,
          })
        ).data;

        if (this.shouldShowBar(onlineVersion)) {
          this.visible = true;
          StorageService.set("lastUpdate", Date.now(), sessionStorage);
        }
      } catch {
        // do nothing
      }
    },
    reload() {
      this.$track("Upgraded App", { version: this.loadedVersion });
      window.location.reload(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.upgrade-bar {
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
</style>
