import { storeToRefs } from "pinia";
import { ref } from "vue";

import useTeamPicker from "shared/composables/useTeamPicker";
import DialogService from "shared/modals/DialogService";
import features from "shared/services/features";
import { useGlobalStore } from "shared/stores/global";

export default function useTeamRouterViewKey() {
  const globalStore = useGlobalStore();
  const { routerViewKey } = storeToRefs(globalStore);
  const { setRouterViewKey } = globalStore;

  const { updateSelectedTeamPreference } = useTeamPicker();

  const cancelledId = ref(null);

  function onCancel(value) {
    cancelledId.value = value;
    updateSelectedTeamPreference(cancelledId.value);
  }

  function isCancelled(value) {
    if (!features.has("has_team_workspaces")) return true;

    return cancelledId.value === value;
  }

  function setExitKey() {
    if (!features.has("has_team_workspaces")) return;

    setRouterViewKey(null);
  }

  function setEmptyKey() {
    if (!features.has("has_team_workspaces")) return;

    setRouterViewKey("");
  }

  function reloadComponents() {
    if (!features.has("has_team_workspaces")) return;

    setRouterViewKey("reload");
  }

  function confirmUnsavedChangesDialog(
    confirmOptions,
    isDirty,
    newValue,
    oldValue
  ) {
    if (!features.has("has_team_workspaces") || isCancelled(newValue)) return;

    if (!isDirty) {
      reloadComponents();

      return;
    }

    DialogService.confirm(confirmOptions)
      .onCancel(() => {
        onCancel(oldValue);
      })
      .onOk(() => {
        reloadComponents();
      });
  }

  if (features.has("has_team_workspaces") && routerViewKey.value === "reload")
    setEmptyKey();

  return {
    setExitKey,
    setEmptyKey,
    confirmUnsavedChangesDialog,
  };
}
