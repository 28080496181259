import { PublicClientApplication } from "@azure/msal-browser";
import { storeToRefs } from "pinia";

import { HOUR_IN_MILLISECONDS } from "shared/constants";
import Poller from "shared/services/polling/Poller";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

let msalInstance;

const loginRequest = {
  scopes: ["User.Read", "email"],
};

function getMsalInstance() {
  const globalStore = useGlobalStore();
  const { appConfig } = storeToRefs(globalStore);

  const { microsoftClientId } = appConfig.value;

  if (!msalInstance) {
    msalInstance = new PublicClientApplication({
      auth: {
        clientId: microsoftClientId,
        redirectUri: `${window.location.origin}/`,
      },
    });
  }

  return msalInstance;
}

export const loginWithMicrosoft = (callback) =>
  getMsalInstance()
    .loginPopup(loginRequest)
    .then((response) => {
      callback(response);
      getMsalInstance().setActiveAccount(response.account);
    });

export const sudoLoginWithMicrosoft = (permission) =>
  new Promise((resolve, reject) => {
    const account = getMsalInstance().getActiveAccount();

    getMsalInstance()
      .loginPopup({ ...loginRequest, prompt: "login", account })
      .then(async ({ idToken }) => {
        await useUserStore().sudo({
          idToken,
          permission,
          provider: "microsoft",
        });

        resolve();
      })
      .catch(reject);
  });

export const refreshMicrosoftLogin = () =>
  new Promise((resolve, reject) => {
    getMsalInstance()
      .ssoSilent(loginRequest)
      .then(async ({ idToken, account }) => {
        await useUserStore().refreshMicrosoftSsoCallback(idToken);
        getMsalInstance().setActiveAccount(account);
        resolve();
      })
      .catch(() => {
        useUserStore().logout();
        reject();
      });
  });

export const MicrosoftLoginPoller = new Poller({
  callback: refreshMicrosoftLogin,
  interval: HOUR_IN_MILLISECONDS,
});
