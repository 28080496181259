import { $streemApiV1 } from "shared/boot/api";

import { getRange } from "../../helpers/date";

export default {
  get({ stream, mentions, options = { range: getRange(365) } }) {
    const { range } = options;

    return $streemApiV1
      .get(`mentions/${stream.id}/similar_mentions`, {
        params: {
          keywords: stream.keywords,
          collapse_syndicated: true,
          ...(options.syndicationKeys
            ? { excluded_syndication_keys: options.syndicationKeys }
            : {}),
          after: range.after,
          before: options.keepAlreadyLoadedMentions
            ? mentions.slice(-1)[0].timestamp
            : range.before,
          excerpt_radius: 150,
          limit: 10,
        },
      })
      .then(({ headers, data }) => ({ headers, data: data.mentions }));
  },
  histogram({ stream, options = { range: getRange(365) } }) {
    const { range } = options;

    return $streemApiV1
      .get(`mentions/${stream.id}/similar_mentions`, {
        params: {
          ...options,
          keywords: stream.keywords,
          collapse_syndicated: true,
          before: range.before,
          after: range.after,
          limit: 0,
        },
      })
      .then(({ headers, data }) => ({ headers, data: data.histogram }));
  },
};
