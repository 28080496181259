<template>
  <JournalistDetail
    :author="author"
    :mention="mention"
  />
</template>

<script>
import JournalistDetail from "shared/components/JournalistDetail.vue";

export default {
  name: "JournalistSideBar",
  components: {
    JournalistDetail,
  },
  props: {
    author: {
      type: String,
      required: true,
    },
    mention: {
      type: Object,
      required: true,
    },
  },
};
</script>
