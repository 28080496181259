<template>
  <div :class="$isDesktop && 'relative fit'">
    <div class="absolute-center text-center">
      <p>
        <QIcon
          name="ion-sad"
          size="5em"
        />
      </p>

      <p>{{ $t("load_error.error") }}</p>

      <QBtn
        color="primary"
        style="width: 200px"
        @click="reloadApp"
      >
        {{ $t("global.try_again") }}
      </QBtn>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadError",
  methods: {
    reloadApp() {
      localStorage.removeItem("appHasReloaded");
      window.location.reload();
    },
  },
};
</script>
