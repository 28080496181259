import features from "shared/services/features";

import useTeamPicker from "./useTeamPicker";

export default function useTeamRoute(route = null) {
  const {
    organisationTeamFromId,
    updateTeamPreferenceMismatch,
    selectedTeamPreference,
  } = useTeamPicker(route);

  function isRouteRestricted(
    organisationTeamId,
    updatePreferenceIfMismatch = true
  ) {
    if (!organisationTeamId || !features.has("has_team_workspaces"))
      return false;

    const organisationTeam = organisationTeamFromId(organisationTeamId);

    if (organisationTeam) {
      if (updatePreferenceIfMismatch) {
        updateTeamPreferenceMismatch(organisationTeam.id);

        return false;
      }

      return selectedTeamPreference.value !== organisationTeamId;
    }

    return true;
  }

  function isPageRestricted(organisationTeamId) {
    return isRouteRestricted(organisationTeamId, false);
  }

  return {
    isRouteRestricted,
    isPageRestricted,
  };
}
