import { initializeApp } from "@firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  reauthenticateWithPopup,
  setPersistence,
  signInWithPopup,
  signOut,
} from "@firebase/auth";

import { HOUR_IN_MILLISECONDS } from "shared/constants";
import Poller from "shared/services/polling/Poller";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

let googleProvider;
let firebaseApp;
let firebaseAuth;

async function initializeFirebase() {
  if (!googleProvider) {
    googleProvider = new GoogleAuthProvider();
    firebaseApp = initializeApp(useGlobalStore().appConfig.firebaseAppConfig);
    firebaseAuth = getAuth(firebaseApp);
    await setPersistence(firebaseAuth, browserLocalPersistence);
  }
}

export const loginWithGoogle = async () => {
  await initializeFirebase();

  const { user } = await signInWithPopup(firebaseAuth, googleProvider);

  const idToken = await user.getIdToken();

  return {
    idToken,
    email: user.email,
  };
};

export const sudoLoginWithGoogle = async (permission) => {
  await initializeFirebase();

  const { user } = await reauthenticateWithPopup(
    firebaseAuth.currentUser,
    googleProvider
  );

  const idToken = await user.getIdToken();

  await useUserStore().sudo({
    idToken,
    permission,
    provider: "google",
  });
};

export const refreshGoogleLogin = async () => {
  await initializeFirebase();

  try {
    const idToken = await firebaseAuth.currentUser.getIdToken(true);

    await useUserStore().refreshGoogleSsoCallback({
      idToken,
      client: "web",
    });
  } catch {
    signOut(firebaseAuth);
    useUserStore().logout();
  }
};

export const GoogleLoginPoller = new Poller({
  callback: refreshGoogleLogin,
  interval: HOUR_IN_MILLISECONDS,
});
