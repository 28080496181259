import Poller from "shared/services/polling/Poller";
import { useAlertsStore } from "shared/stores/alerts";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

async function callback() {
  const { fetchAlertCount } = useAlertsStore();

  if (!useUserStore().isLoggedIn || useGlobalStore().technicalServerError) {
    return;
  }

  await fetchAlertCount();
}

export default new Poller({ callback });
