/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config file > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import iconSet from 'quasar/icon-set/ionicons-v4.js'



import {QAvatar,QBanner,QBtn,QBtnDropdown,QCardSection,QCardActions,QCard,QCarousel,QCarouselSlide,QCheckbox,QChip,QColor,QDate,QDialog,QEditor,QExpansionItem,QForm,QIcon,QImg,QInfiniteScroll,QInnerLoading,QInput,QIntersection,QItem,QItemLabel,QItemSection,QLayout,QList,QMenu,QPage,QPageContainer,QPagination,QPopupProxy,QRadio,QRange,QSelect,QSeparator,QResizeObserver,QSlideTransition,QSlider,QSpinner,QSpinnerDots,QStep,QToggle,QTabs,QTab,QTooltip,ClosePopup,Intersection,Ripple,TouchPan,Dialog,Notify,Loading} from 'quasar'



export default { config: {},iconSet,components: {QAvatar,QBanner,QBtn,QBtnDropdown,QCardSection,QCardActions,QCard,QCarousel,QCarouselSlide,QCheckbox,QChip,QColor,QDate,QDialog,QEditor,QExpansionItem,QForm,QIcon,QImg,QInfiniteScroll,QInnerLoading,QInput,QIntersection,QItem,QItemLabel,QItemSection,QLayout,QList,QMenu,QPage,QPageContainer,QPagination,QPopupProxy,QRadio,QRange,QSelect,QSeparator,QResizeObserver,QSlideTransition,QSlider,QSpinner,QSpinnerDots,QStep,QToggle,QTabs,QTab,QTooltip},directives: {ClosePopup,Intersection,Ripple,TouchPan},plugins: {Dialog,Notify,Loading} }

