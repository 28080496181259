<template>
  <div class="c-splashscreen">
    <div class="c-splashscreen__logo-container">
      <img
        :alt="
          $t('splashscreen.platform_logo', {
            platformName: appConfig.platformName,
          })
        "
        class="c-splashscreen__logo"
        :src="appConfig.login.logo"
      />
      <slot>
        <QSpinner
          :size="39"
          color="white"
          class="q-ma-md"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import { useGlobalStore } from "shared/stores/global";

export default {
  name: "Splashscreen",
  setup() {
    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    return {
      appConfig,
    };
  },
};
</script>

<style lang="scss" scoped>
.c-splashscreen {
  background: var(--s-splashscreen);
  width: 100%;
  height: 100%;
  margin: auto 0;
  position: absolute;

  &__logo {
    width: 12em;

    &-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-flow: column wrap;
      align-items: center;
    }
  }
}
</style>
