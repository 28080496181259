import { storeToRefs } from "pinia";
import { computed, getCurrentInstance } from "vue";

import {
  crmFeatures,
  outreachFeatures,
  socialFeatures,
  teaserFeatures,
} from "shared/boot/authorization";
import features from "shared/services/features";
import { useStreamGroupsStore } from "shared/stores/streamGroups";
import { useStreamsStore } from "shared/stores/streams";

import useProductRouting from "./useProductRouting";

export default function useProductMenu(isDesktopApp = false) {
  const { routeProductName } = useProductRouting();

  const root = getCurrentInstance()?.proxy;

  const mediaProductMenuEnabled = computed(
    () => features.has("has_media_platform") || root.$isMobile
  );

  const socialProductMenuEnabled = computed(() =>
    socialFeatures.canViewSocialPlatform()
  );

  const crmMenuEnabled = computed(() => crmFeatures.canViewCrmPlatform());

  const outreachProductMenuEnabled = computed(() =>
    outreachFeatures.canViewOutreachPlatform()
  );

  const socialProductTeaserEnabled = computed(() =>
    teaserFeatures.canViewSocialPlatformTeaser()
  );

  const outreachProductTeaserEnabled = computed(() =>
    teaserFeatures.canViewOutreachPlatformTeaser()
  );

  const cslProductMenuEnabled = computed(
    () => features.has("has_csl") && root.$isDesktop
  );

  const streamsStore = useStreamsStore();
  const streamGroupsStore = useStreamGroupsStore();

  const { socialStreams } = streamsStore;
  const { socialStreamGroups } = storeToRefs(streamGroupsStore);

  function firstSocialGroupWithStreams() {
    const filteredGroups = socialStreamGroups
      .value()
      .filter((group) => group.streams.length);

    return filteredGroups.length ? filteredGroups[0] : null;
  }

  function displayPrimaryStream(stream) {
    return !stream.primary || !stream.dashboard_only;
  }

  function socialStreamsByGroupId(groupId) {
    return socialStreams().filter(
      (stream) => displayPrimaryStream(stream) && stream.group_id === groupId
    );
  }

  const mediaRoute = computed(() => ({ name: "launchpad" }));

  const socialRoute = computed(() => {
    const group = firstSocialGroupWithStreams();
    const streams = group ? socialStreamsByGroupId(group.id) : [];

    let routeDetails = {};

    const isDesktop = isDesktopApp || root.$isDesktop;

    if (socialFeatures.canViewSocialListen()) {
      if (!streams.length) {
        routeDetails = isDesktop
          ? { name: "social-monitor" }
          : { name: "social" };
      } else {
        routeDetails = {
          name: "social-monitor",
          params: {
            groupSlug: group.slug,
            streamSlug: streams?.[0]?.slug,
          },
        };
      }
    } else if (socialFeatures.canViewSocialEngage()) {
      routeDetails = isDesktop ? { name: "social-engage" } : {};
    }

    return routeDetails;
  });

  const cslRoute = computed(() => ({ name: "csl" }));

  const competitorRoute = computed(() => ({ name: "competitor-dashboard" }));

  const crmRoute = computed(() => ({ name: "crm-activities" }));

  const outreachRoute = computed(() => {
    let name = "outreach-campaigns";

    if (features.has("has_outreach_2")) name = "outreach-launchpad";

    return { name };
  });

  const currentRouteProductMenu = computed(() => routeProductName());

  return {
    cslRoute,
    mediaRoute,
    socialRoute,
    competitorRoute,
    crmRoute,
    outreachRoute,
    mediaProductMenuEnabled,
    socialProductMenuEnabled,
    crmMenuEnabled,
    currentRouteProductMenu,
    outreachProductMenuEnabled,
    socialProductTeaserEnabled,
    outreachProductTeaserEnabled,
    cslProductMenuEnabled,
  };
}
